import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import * as styles from "./whoWeAre.module.scss"
import JSONData from "../../../content/whoWeAreData.json"
import {Col, Row} from "react-bootstrap";
import {LinkButton} from "../linkButton/linkButton";


export default function WhoWeAre() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "services/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image2: file(relativePath: {eq: "services/image-2.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image3: file(relativePath: {eq: "services/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        }
    }`);

    return <div>
        <div className={styles.images}>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image1.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={`${styles.image} ${styles.imageHigher}`} alt={"person"}
                             image={data.image2.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={`${styles.imageWrapper}  ${styles.imageHideMobile}`}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image3.childImageSharp.gatsbyImageData}/>
            </div>
        </div>
        <Row>
            <Col md={{offset: 1, span: 10}} lg={{offset: 2, span: 8}}>
                <div className={styles.sectionTitle}>{JSONData.sectionTitle}</div>
                <div className={styles.header}>{JSONData.header}</div>
                <div className={styles.text}>{JSONData.text}</div>

            </Col>
            <Col lg={{offset: 1, span: 10}}>
                <div className={styles.statsWrapper}>
                    {JSONData.statistics.map((data: any, idx: number) => <Statistic key={idx} text={data.text}
                                                                                    stat={data.stat}/>)}
                </div>
            </Col>
            <div className={styles.buttonWrapper}>
                <LinkButton to={"/aboutUs"}>More about us</LinkButton>
            </div>
        </Row>
    </div>
}

function Statistic(props: { stat: string, text: string }) {
    return <div className={styles.statWrapper}>
        <div className={styles.stat}>{props.stat}</div>
        <div className={styles.statText}>{props.text}</div>
    </div>
}
