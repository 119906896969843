import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import React from "react";
import Layout from "../components/layout/layout";
import ServicesCardList from "../components/serviceCard/servicesCardList";
import ExpertsSection from "../components/ourServices/expertsSection/expertsSection";
import * as styles from "../styles/services.module.scss";
import DiversitySection from "../components/ourServices/diversitySection/diversitySection";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/servicePageHeroData.json"
import WhoWeAre from "../components/whoWeAre/whoWeAre";
import Cta from "../components/cta/cta";
import OurWork from "../components/ourWork/ourWork";
import {Helmet} from "react-helmet";

export default function ServicesPage() {
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>Services - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero text={JSONData.text} sectionTitle={JSONData.sectionTitle} header={JSONData.header}/>
            <ServicesCardList/>
            <div className={styles.expertSectionWrapper}>
                <ExpertsSection/>
            </div>
            <div className={styles.verticalBreak}/>
            <WhoWeAre/>
            <div className={styles.verticalBreak}/>
            <div className={styles.diversitySectionWrapper}>
                <DiversitySection/>
            </div>
            <OurWork/>
            <Cta title="Book a chat with us today" buttonText="Book a call" buttonTarget="/bookACall" />
        </Layout>
    </>
}
